import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import guidlines from './Authors- guidelines.pdf'
import { Link, useParams } from 'react-router-dom';
import AxiosHelper from '../../helper/AxiosHelper';
import { toast } from 'react-toastify';


const CaseStudy = () => {
	const params = useParams()
	const [categoryArticles, setCategoryArticles] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		getCategories()
	}, []);

	const getCategories = async () => {
		var { data } = await AxiosHelper.getData(`doctor-corners?catlist=${params?.category_slug}`);

		if (data.status === true) {
			setCategoryArticles(data.data)
			setIsLoading(false)
		} else {
			toast.error(data.message);
		}
	}
	return (
		<>
			<Banner title={categoryArticles[0]?.categories[0]?.name} />
			<Container className='case-study-skeleton d-flex'>
				{isLoading ? (
					Array(2).fill(0).map((_, index) => (
						<Row className="mb-5 mt-5 d-flex justify-content-center" key={index}>
							{/* <div className="cardBox"> */}
							<div
								className="skeleton-img"
								style={{
									borderRadius: "20px",
									width: "180px",
									height: "150px",
									marginLeft: "30px",
								}}
							>

							</div>
							<div className="skeleton-title" ></div>
							{/* </div> */}
						</Row>
					))
				) : (
					categoryArticles.map((item, index) => {

						return (
							<Row className="mb-5 mt-5" key={index}>
								{item?.document_file ?
									<a
										href={item?.document_file}
										key={item.id}
										className="list-nav"
										style={{ textDecoration: "none" }}
										target="_blank"
										rel="noopener noreferrer"
										download
									>
										<div className="cardBox">
											<img
												style={{
													borderRadius: "20px",
													width: "200px",
													height: "150px",
													marginLeft: "30px",
												}}
												src={item.image}
												alt={item.title || "Image"}
											/>
											<h3 className="cardTitle mt-4">{item.title}</h3>
										</div>
									</a>
									:
									<a href="javascript:void(0)" ></a>
								}
							</Row>
						);
					})
				)}
			</Container>
		</>
	)   
}

export default CaseStudy;

export const Banner = ({ title }) => {
	return (
		<div className='bannerBody'>
			<h1 className='bannerHeading'>{title}</h1>
			{/* <h6 className='subBannerHeading'><a target='_blank' href={guidlines} rel="noreferrer">Guidelines for Article Submission</a></h6> */}
		</div>
	)
}