import { useEffect, useState } from "react";
import AxiosHelper from "../../helper/AxiosHelper";
import { toast } from "react-toastify";
import guidlines from '../DoctorCaseStudies/Authors- guidelines.pdf'
import { useParams } from "react-router-dom";

const Article = () => {
	const [article, setArticle] = useState({})
	const [isLoading, setIsLoading] = useState(true)

	const params = useParams()

	useEffect(() => {
		getArticles()
	}, []);

	const getArticles = async () => {
		var { data } = await AxiosHelper.getData(`doctor-corners/${params?.category_slug}/${params?.article_slug}`);

		setArticle(data?.data)
		setIsLoading(false)
		if (data.status === true) {
		} else {
			toast.error(data.message);
		}
	}

	return (
		<>
			<Banner title='Clinical Case Studies-Article' />
			<div className="article-skeleton">
				<div style={{ marginLeft: '550px' }}>
					{isLoading ? (
						<div className="cardBox">
							<div
								className="skeleton-img"
								style={{ borderRadius: '20px', width: '400px', height: '350px' }}
							></div>
							<div className="skeleton-title mt-4 " style={{ marginLeft: '120px' }}></div>
							<div className="skeleton-input" style={{ marginLeft: '100px' }}></div>
						</div>
					) : (
						<div className="cardBox mt-4">
							<img
								style={{ borderRadius: '20px', width: '400px', height: '350px' }}
								src={article.image}
								alt=""
							/>
							<h3 className="cardTitle mt-4" style={{marginLeft:"80px"}}>{article.title}</h3>
							<a
								href={guidlines}
								download="Custom-File-Name.pdf" 
								className="btn btn-primary " style={{marginLeft:"90px", marginBottom:"10px"}}
							>
								Download File 
							</a>
						</div>
					)}
				</div>
			</div>
		</>
	)
}
export default Article

export const Banner = ({ title }) => {
	return (
		<div className='bannerBody'>
			<h1 className='bannerHeading'>{title}</h1>
		</div>
	)
}